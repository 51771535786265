import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image"

const ArticleComponent = ({ item, isFrontPage, featured }) => {
  const featuredImage = {
    data: item.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.featuredImage?.node?.altText || ``,
  }

  const date = item.date;
  const wrappedContent = isFrontPage ? "frontPageWrapper" : "contentWrapper";

  return (
    <NewsArticle>
      <Link to={item.uri}>
        {featuredImage?.data && !featured && (
          <GatsbyImage
            image={featuredImage.data}
            alt={featuredImage.alt}
            style={{ 
              height: 90,
              width: 150,
              borderRadius: 4
            }}
          />
        )}
      </Link>
      
      <div className={wrappedContent}>
        <Link to={item.uri}>
          <div className="articleTitle">{item.title}</div>
        </Link>
        <div className="authorWrapper">
          {item?.date && ( <span>{date}</span> )}
        </div>
        
      </div>
    </NewsArticle>
  );
}

export default ArticleComponent;

const NewsArticle = styled.div`
  width: 100%;
  height: min-content;
  row-gap: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;

  a {
    text-decoration: none;
  }

  img {
    border-radius: 4px !important;
  }

  .articleTitle {
    margin: 0;
    font-size: 18px;
    color: var(--color-heading);
    font-weight: 600;
    font-family: var(--fontFamily-sans);
    line-height: 1.2;
    display: block;
    letter-spacing: -0.025em;
  }

  span {
    font-size: 12px;
    font-weight: var(--fontWeight-medium);
    color: var(--color-light);
  }

  p {
    font-size: 12px;
    line-height: 1.3;
    font-weight: var(--fontWeight-medium);
    color: var(--color-text);
    margin: 5px 0 0;

    a {
      display: none;
    }
  }

  .authorWrapper {
    display: flex;
    grid-gap: 8px;
    align-items: center;
    margin-top: 5px;
    text-transform: capitalize;

    a {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }
  }
`;